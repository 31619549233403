import React from "react";

import mail from "../img/social/mail.svg";
import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";
import youtube from "../img/social/youtube.svg";
import vimeo from "../img/social/vimeo.svg";

const Footer = class extends React.Component {
  render() {
    return (
      <div className="wrapper">
        <footer>
          <div className="social-links">
            <a
              title="Email us"
              href="mailto:contact@jollygoodweddingfilms.co.uk"
            >
              <img
                className="svg"
                src={mail}
                alt="Email us"
                style={{ width: "1em", height: "1em" }}
              />
            </a>
            <a
              title="Facebook"
              href="https://facebook.com/jollygoodweddingfilms"
            >
              <img
                className="svg"
                src={facebook}
                alt="Facebook"
                style={{ width: "1em", height: "1em" }}
              />
            </a>
            <a
              title="YouTube"
              href="https://www.youtube.com/channel/UCt3MLsqJXTcl9pWZNIyfkDQ"
            >
              <img
                className="svg"
                src={youtube}
                alt="YouTube"
                style={{ width: "1em", height: "1em" }}
              />
            </a>
            <a
              title="Instagram"
              href="https://instagram.com/jolly.good.wedding.films"
            >
              <img
                className="svg"
                src={instagram}
                alt="Instagram"
                style={{ width: "1em", height: "1em" }}
              />
            </a>
            <a title="Vimeo" href="https://vimeo.com/jollygoodweddingfilms">
              <img
                className="svg"
                src={vimeo}
                alt="Vimeo"
                style={{ width: "1em", height: "1em" }}
              />
            </a>
          </div>
        </footer>
      </div>
    );
  }
};

export default Footer;
