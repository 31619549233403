import React from "react";
const KEY = "announcementbanner";

const Announcement = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      renderAnnouncement:
        typeof window !== "undefined" &&
        window.localStorage &&
        !window.localStorage.getItem(KEY),
    };
  }

  close = () => {
    this.setState({ renderAnnouncement: false });
    window.localStorage.setItem(KEY, true);
  };

  render() {
    const { message } = this.props;
    return this.state.renderAnnouncement && message ? (
      <section>
        <div className="announcement" aria-hidden>
          <div className="wrapper">
            <span>{message}</span>
          </div>
          <div
            className="announcement__close"
            onClick={this.close}
            aria-hidden="true"
          >
            &times;
          </div>
        </div>
      </section>
    ) : null;
  }
};

export default Announcement;
