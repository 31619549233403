import React from "react";
import { Link } from "gatsby";
import logo from "../img/logo.svg";

const Navbar = class extends React.Component {
  render() {
    return (
      <nav role="navigation" aria-label="main-navigation">
        <div className="wrapper">
          <div className="nav">
            <div className="nav__center">
              <Link to="/" title="Logo">
                <img
                  className="svg"
                  src={logo}
                  alt="Jolly Good Wedding Films"
                  style={{ width: "150px" }}
                />
              </Link>
            </div>
            <div className="nav__side nav__side--left">
              <Link to="/">Home</Link>
              <Link to="/films">Films</Link>
              <Link to="/blog">Blog</Link>
            </div>
            <div className="nav__side nav__side--right">
              <Link to="/about">About</Link>
              <Link to="/packages">Packages</Link>
              <Link to="/contact">Contact</Link>
            </div>
          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;
